.home-page {

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    max-height: 90%;
  }

  h1{
    color: white;
    font-size: 53px;
    margin: 0;
    font-family: 'Coolvetica', sans-serif;
    font-weight: 400;

    &::before{
      content: '<h1>';
      font-family: 'La Belle Aurore', sans-serif;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: 15px;
      opacity: 0.6;
    }

    &::after{
      content: '</h1>';
      font-family: 'La Belle Aurore', sans-serif;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      opacity: 0.6;
      animation: fadeIn 1s 1.7s backwards;
    }

    img{
      width: 50px;
      margin-left: 20px;
      opacity: 0;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }
}


h2 {
  color: #8d8d8d;
  margin-top: 20px;
  font-weight: 400;
  font-size: 11px;
  font-family: sans-serif;
  letter-spacing: 3px;
  animation: fadeIn 1s 1.8s backwards;
}

.flat-button{
  color: #ffd700;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #ffd700;
  margin-top: 25px;
  float: left;
  animation: fadeIn 1s 1.8s backwards;
  white-space: nowrap;
  transition: all 0.3s;


  &:hover {
    background: #ffd700;
    color: #333;
    transition: all 0.3s;
  }
}


//======================= MEDIA ============

@media only screen and (max-width: 600px) {

  .home-page {

    .text-zone{
      width: 90vw !important;
      overflow: paged-x hidden;
      top: 35%;
    }

    h1{
      font-size: 30px !important;

      img{
        width: 30px !important;
      }
    }
  }



}